
























import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import { EventBus } from '@/utils/event-bus'
import { Region } from '@/store/modules/region'
import { Market } from '@/store/modules/market'

@Component
export default class MarketSelector extends Vue {
  @Prop({ default: false })
  visible!: boolean;

  activeTabIndex: number = 0

  get regions () {
    return this.$store.getters.getRegions
  }

  get selectedMarketKey () {
    return this.$store.getters.getSelectedMarket
  }

  @Emit('selectMarket')
  selectMarket (marketKey: string) {
    EventBus.$emit('show-loader')
    this.togglePanel(false)
    this.$store.dispatch('setSelectedMarket', marketKey).then(() => {
      EventBus.$emit('market-selected')
      EventBus.$emit('hide-loader')
    })
  }

  @Emit('show')
  togglePanel (visible: boolean) {
    return visible
  }

  async mounted () {
    await this.$store.dispatch('loadRegions')
    if (!this.selectedMarketKey.length && (this.regions && this.regions.length)) {
      this.selectMarket(this.regions[0].markets[0].key)
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange () {
    if (!this.visible) {
      return
    }
    this.togglePanel(false)
  }

  @Watch('visible')
  toggle () {
    this.activeTabIndex = this.regions.findIndex((region: Region) => {
      return region.markets.map((market: Market) => market.key).includes(this.selectedMarketKey)
    })
  }
}
