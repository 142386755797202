import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import axios from 'axios'
import { Resource, SlideDeckResourceNumber } from './resource'
import { Product } from './product'
import { Market } from './market'
import store from '@/store/store'

export interface SlideDeck {
  key: string,
  title: string,
  description: string,
  disabled: boolean,
  semVersion: string,
  pdfFilename?: string,
  pptxFilename?: string,
  // eslint-disable-next-line no-use-before-define
  slideDeckSections: SlideDeckSection[],
  product: Product,
  markets: Market[],
  hasChanges: boolean,
  isDraft: boolean,
  slideDeckResourceNumbers: SlideDeckResourceNumber[],
  draftEntity?: SlideDeck,
  publishedEntity: SlideDeck,
}
export interface SlideDeckSection {
  key: string,
  title: string,
  orderIdx: number,
  // eslint-disable-next-line no-use-before-define
  slideDeckPages: SlideDeckPage[]
  slideDeck: SlideDeck,
}
export interface SlideDeckPage {
  key: string,
  title: string,
  orderIdx: number,
  resources: Resource[],
  slideDeckSection: SlideDeckSection,
  pageNo: number,
  required: boolean,
  publishedKey: string
}
export interface SlideDeckUpdate {
  key: string,
  semVersion: string,
  summary: string,
  description: string,
  createdAt: string
}

@Module
export default class SlideDeckStore extends VuexModule {
  apiUrlRoot: string = '/slide-decks'
  slideDecks: SlideDeck[] = []
  // active slideDeck
  activeSlideDeck: SlideDeck = {
    key: '',
    title: '',
    slideDeckSections: [],
    markets: []
  } as any

  activeSlideDeckSections?: SlideDeckSection[]
  activeSlideDeckPages?: SlideDeckPage[]
  activeSlideDeckSection?: SlideDeckSection
  activeSlideDeckPage?: SlideDeckPage
  activeSlideDeckChangeLog?: SlideDeckUpdate[]

  customSlideDeck: any = {
    slides: [],
    slideDeckKey: ''
  }

  customSlideDecks: any = sessionStorage.getItem('customSlideDecks') ? JSON.parse(sessionStorage.getItem('customSlideDecks') as string) : []

  @Mutation
  SET_SLIDEDECKS (slideDecks: SlideDeck[]) {
    this.slideDecks = slideDecks
  }

  @Mutation
  SET_ACTIVE_SLIDEDECK (slideDeck: SlideDeck) {
    this.activeSlideDeck = slideDeck
  }

  @Mutation
  SET_ACTIVE_SLIDEDECK_SECTIONS (slideDeckSections: SlideDeckSection[]) {
    this.activeSlideDeckSections = slideDeckSections
  }

  @Mutation
  SET_ACTIVE_SLIDEDECK_PAGES (slideDeckPages: SlideDeckPage[]) {
    this.activeSlideDeckPages = slideDeckPages
  }

  @Mutation
  SET_ACTIVE_SLIDEDECK_SECTION (slideDeckSection: SlideDeckSection) {
    this.activeSlideDeckSection = slideDeckSection
  }

  @Mutation
  SET_ACTIVE_SLIDEDECK_PAGE (slideDeckPage: SlideDeckPage) {
    this.activeSlideDeckPage = slideDeckPage
  }

  @Mutation
  SET_ACTIVE_SLIDEDECK_CHANGE_LOG (updates: SlideDeckUpdate[]) {
    this.activeSlideDeckChangeLog = updates
  }

  @Mutation
  SET_CUSTOM_DECK_SLIDES (slides: any) {
    this.customSlideDeck.slides = slides
    const _indexOfDeck = this.customSlideDecks.findIndex((deck: any) => deck.slideDeckKey === this.customSlideDeck.slideDeckKey)
    if (_indexOfDeck >= 0) {
      this.customSlideDecks[_indexOfDeck] = this.customSlideDeck
    } else {
      this.customSlideDecks.push(this.customSlideDeck)
    }

    sessionStorage.setItem('customSlideDecks', JSON.stringify(this.customSlideDecks))
  }

  @Mutation
  SET_CUSTOM_DECK (customSlideDeck: any) {
    this.customSlideDeck = customSlideDeck
    const _indexOfDeck = this.customSlideDecks.findIndex((deck: any) => deck.slideDeckKey === customSlideDeck.slideDeckKey)
    if (_indexOfDeck >= 0) {
      this.customSlideDecks[_indexOfDeck] = customSlideDeck
    } else {
      this.customSlideDecks.push(customSlideDeck)
    }

    sessionStorage.setItem('customSlideDecks', JSON.stringify(this.customSlideDecks))
  }

  @Action({ commit: 'SET_SLIDEDECKS' })
  async loadSlideDecks () {
    const result = await axios.get(this.apiUrlRoot, { withCredentials: true })

    const data: SlideDeck[] = result.data
    return data
  }

  @Action({ commit: 'SET_SLIDEDECKS' })
  async loadSlideDecksByProductKey (productKey: string) {
    const result = await axios.get(`${this.apiUrlRoot}/by-product/${productKey}`, { withCredentials: true })

    const data: SlideDeck[] = result.data
    return data
  }

  @Action async addSlideDeck (newSlideDeck: SlideDeck) {
    const result = await axios.post(`${this.apiUrlRoot}`, newSlideDeck, { withCredentials: true })
    const data: SlideDeck = result.data
    return data
  }

  @Action({ commit: 'SET_ACTIVE_SLIDEDECK_CHANGE_LOG' })
  @Action async loadSlideDeckChangeLog (slideDeckKey: string) {
    const result = await axios.get(`${this.apiUrlRoot}/updates/${slideDeckKey}`, { withCredentials: true })
    const data: SlideDeckUpdate[] = result.data
    return data
  }

  @Action({ commit: 'SET_ACTIVE_SLIDEDECK' })
  async loadSlideDeck (slideDeckKey: string) {
    const uri: string = (process.env.MULTI_MARKET_REFERENCES === 'true' || process.env.MULTI_MARKET_REFERENCES === true) ? `${this.apiUrlRoot}/${slideDeckKey}` : `${this.apiUrlRoot}/${slideDeckKey}/market/${store.getters.getSelectedMarket}`
    const result = await axios.get(uri, { withCredentials: true })

    const slideDeck: SlideDeck = result.data
    return slideDeck
  }

  @Action({ commit: 'SET_ACTIVE_SLIDEDECK' })
  async loadSlideDeckAdmin (slideDeckKey: string) {
    const result = await axios.get(`${this.apiUrlRoot}/${slideDeckKey}`, { withCredentials: true })

    const slideDeck: SlideDeck = result.data
    return slideDeck
  }

  @Action({ commit: 'SET_ACTIVE_SLIDEDECK' })
  async updateSlideDeck (slideDeck: SlideDeck) {
    if (slideDeck.hasChanges) {
      await axios.post(`${this.apiUrlRoot}/updated/${slideDeck.key}`, { withCredentials: true })
    }
    return slideDeck
  }

  @Action({ commit: 'SET_ACTIVE_SLIDEDECK_SECTIONS' })
  async loadSlideDeckSections (slideDeckSections: SlideDeckSection[]) {
    return slideDeckSections
  }

  @Action({ commit: 'SET_ACTIVE_SLIDEDECK_PAGES' })
  async loadSlideDeckPages (slideDeckSections: SlideDeckSection[]) {
    let _slideDeckPages: SlideDeckPage[] = []
    slideDeckSections.forEach((slideDeckSection: SlideDeckSection) => {
      _slideDeckPages = _slideDeckPages.concat(slideDeckSection.slideDeckPages)
    })
    return _slideDeckPages
  }

  @Action({ commit: 'SET_ACTIVE_SLIDEDECK_SECTION' })
  setActiveSlideDeckSection (slideDeckSection: SlideDeckSection) {
    return slideDeckSection
  }

  @Action({ commit: 'SET_ACTIVE_SLIDEDECK_PAGE' })
  // @MutationAction({ mutate: ['activeSlideDeckPage', 'activeSlideDeckSection'] })
  setActiveSlideDeckPage (pageNo: number) {
    let slideDeckPageIndex: number
    let slideDeckSection: SlideDeckSection | null = null
    for (let i = 0; i < this.activeSlideDeck.slideDeckSections.length; i++) {
      slideDeckSection = this.activeSlideDeck.slideDeckSections[i]
      if (slideDeckSection.slideDeckPages.length) {
        slideDeckPageIndex = slideDeckSection.slideDeckPages.findIndex(page => {
          return page.pageNo === pageNo
        })
        if (slideDeckPageIndex >= 0) {
          return slideDeckSection.slideDeckPages[slideDeckPageIndex]
        }
      }
    }
  }

  @Action({ commit: 'SET_ACTIVE_SLIDEDECK' })
  async publishSlideDeck ({ slideDeck, updateDetails }: any) {
    const result = await axios.post(`${this.apiUrlRoot}/publish`, { slideDeck, updateDetails }, { withCredentials: true })
    const data: SlideDeck = result.data
    return data
  }

  @Action async saveSlideDeck (slideDeckToSave: SlideDeck) {
    const result = await axios.post(`${this.apiUrlRoot}`, slideDeckToSave, { withCredentials: true })
    return result
  }

  @Action async saveSlideDeckFile ({ key, formData } : { key: string, formData: FormData}) {
    const result = await axios.post(`${this.apiUrlRoot}/file-upload/${key}`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true })
    return result
  }

  @Action async addNewSlideDeckSection (slideDeckSectionToAdd: SlideDeckSection) {
    const result = await axios.post('/slide-deck-sections/', slideDeckSectionToAdd, { withCredentials: true })
    return result
  }

  @Action async saveSlideDeckSection (slideDeckSectionToSave: SlideDeckSection) {
    const result = await axios.post('/slide-deck-sections/', slideDeckSectionToSave, { withCredentials: true })
    return result
  }

  @Action async deleteSlideDeckSection (sectionKeyToDelete: string) {
    const result = await axios.delete(`/slide-deck-sections/${sectionKeyToDelete}`, { withCredentials: true })
    return result
  }

  @Action async addNewSlideDeckPage (slideDeckPageToAdd: SlideDeckPage) {
    const result = await axios.post('/slide-deck-pages/', slideDeckPageToAdd, { withCredentials: true })
    return result
  }

  @Action async saveSlideDeckPage (slideDeckPageToSave: SlideDeckPage) {
    const result = await axios.post('/slide-deck-pages/', slideDeckPageToSave, { withCredentials: true })
    return result
  }

  @Action async deleteSlideDeckPage (pageKeyToDelete: string) {
    const result = await axios.delete(`/slide-deck-pages/${pageKeyToDelete}`, { withCredentials: true })
    return result
  }

  @Action async reorderSlideDeckSection (sectionToReorder: SlideDeckSection) {
    const result = await axios.post('/slide-deck-sections/reorder', sectionToReorder, { withCredentials: true })
    return result
  }

  @Action async reorderSlideDeckPage (pageToReorder: SlideDeckPage) {
    const result = await axios.post('/slide-deck-pages/reorder', pageToReorder, { withCredentials: true })
    return result
  }

  @Action async addNewSlideDeckMarket ({ slideDeckKey, marketKey }: any) {
    const result = await axios.post(`${this.apiUrlRoot}/add-market/`, { slideDeckKey, marketKey }, { withCredentials: true })
    return result
  }

  @Action async deleteSlideDeckMarket ({ slideDeckKey, marketKey }: any) {
    const result = await axios.post(`${this.apiUrlRoot}/delete-market/`, { slideDeckKey, marketKey }, { withCredentials: true })
    return result
  }

  // custom slide deck actions

  @Action({ commit: 'SET_CUSTOM_DECK' })
  async loadCustomDeck (slideDeckKey: string) {
    const _customDeck = this.customSlideDecks.find((deck: any) => deck.slideDeckKey === slideDeckKey) || {
      slides: [],
      slideDeckKey: slideDeckKey
    }

    if (!this.activeSlideDeckPages) {
      return _customDeck
    }

    this.activeSlideDeckPages.forEach((slideDeckPage: SlideDeckPage) => {
      const _slide = _customDeck.slides.find((customSlide: any) => customSlide.key === slideDeckPage.key)
      if (_slide === undefined && slideDeckPage.required) {
        _customDeck.slides.push({ key: slideDeckPage.key, pageNo: slideDeckPage.pageNo })
      }
    })
    return _customDeck
  }

  @Action({ commit: 'SET_CUSTOM_DECK_SLIDES' })
  async addSlideToCustomDeck (slideKey: string) {
    if (!this.activeSlideDeckPages || !this.activeSlideDeckPages.length) {
      return []
    }
    const _slides = this.customSlideDeck.slides
    if (!this.customSlideDeck.slides.find((slide: SlideDeckPage) => slide.key === slideKey) !== undefined) {
      const _page = this.activeSlideDeckPages.find((slide: SlideDeckPage) => slide.key === slideKey)
      if (_page) {
        _slides.push({ key: slideKey, pageNo: _page.pageNo })
      }
    }
    return _slides
  }

  @Action({ commit: 'SET_CUSTOM_DECK_SLIDES' })
  async removeSlideFromCustomDeck (slideKey: string) {
    const _slides = this.customSlideDeck.slides
    if (_slides.find((slide: SlideDeckPage) => slide.key === slideKey) !== undefined) {
      _slides.splice(_slides.findIndex((slide: any) => slide.key === slideKey), 1)
    }
    return _slides
  }

  @Action({ commit: 'SET_CUSTOM_DECK_SLIDES' })
  async removeAllCustomDeckSlides () {
    this.customSlideDecks.filter((deck: any) => deck.slideDeckKey !== this.customSlideDeck.slideDeckKey)
    sessionStorage.setItem('customSlideDecks', JSON.stringify(this.customSlideDecks))

    const _requiredSlideKeys = (this.activeSlideDeckPages as SlideDeckPage[]).filter((slide: SlideDeckPage) => slide.required).map((slide: SlideDeckPage) => slide.key)
    const _newBaseSlides: any[] = []
    _requiredSlideKeys.forEach((requiredKey: string) => {
      const _requiredSlide = ((this.activeSlideDeckPages as SlideDeckPage[]).find((slide: SlideDeckPage) => slide.key === requiredKey) as SlideDeckPage)
      _newBaseSlides.push({ key: requiredKey, pageNo: _requiredSlide.pageNo })
    })
    return _newBaseSlides
  }

  // getters

  get getSlideDecks () {
    return this.slideDecks
  }

  get getActiveSlideDeck () {
    return this.activeSlideDeck
  }

  get getSlideDeckByKey () {
    return (key: string) => {
      return this.slideDecks.find(slideDeck => slideDeck.key === key) || null
    }
  }

  get getActiveSlideDeckSections () {
    return this.activeSlideDeckSections
  }

  get getActiveSlideDeckPages () {
    return this.activeSlideDeckPages
  }

  get getActiveSlideDeckSection () {
    return this.activeSlideDeckSection
  }

  get getActiveSlideDeckPage () {
    return this.activeSlideDeckPage
  }

  get getCustomSlideDeck () {
    return this.customSlideDeck
  }
}
