import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import axios from 'axios'
import { Market } from './market'

export interface Region {
  name: string,
  key: string,
  markets: Market[]
}

@Module
export default class RegionStore extends VuexModule {
  apiUrlRoot: string = '/regions'
  regions: Region[] = []

  @Mutation
  SET_REGIONS (regions: Region[]) {
    this.regions = regions
  }

  @Action({ commit: 'SET_REGIONS' })
  async loadRegions () {
    const result = await axios.get(this.apiUrlRoot, { withCredentials: true })
    const data: Region[] = result.data
    return data
  }

  @Action async addRegion (newRegion: Region) {
    const result = await axios.post(`${this.apiUrlRoot}`, newRegion, { withCredentials: true })
    const data: Region = result.data
    return data
  }

  @Action async saveRegion (regionToSave: Region) {
    const result = await axios.post(`${this.apiUrlRoot}`, regionToSave, { withCredentials: true })
    return result
  }

  @Action async deleteRegion (regionToDelete: Region) {
    const result = await axios.delete(`${this.apiUrlRoot}/${regionToDelete.key}`, { withCredentials: true })
    return result
  }

  get getRegions () {
    return this.regions
  }

  get getRegionByKey () {
    return (key: string) => {
      return this.regions.find(region => region.key === key) || {
        name: ''
      }
    }
  }
}
