import '@babel/polyfill'
// import 'intersection-observer' // Optional
import Vue from 'vue'
import './icons'
import Component from 'vue-class-component'
import App from './App.vue'
import router from './router'
import store from './store/store'
import browserDetect from 'vue-browser-detect-plugin'
import moment from 'moment'
import BootstrapVue from 'bootstrap-vue'
import VueDraggable from 'vue-draggable'
import './styles/index.scss'
import '@/directives/visible'

// before you will make any import
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate' // for vue-router 2.2+
])

Vue.use(browserDetect)

Vue.use(BootstrapVue)
Vue.config.productionTip = false

Vue.use(VueDraggable)

// date formatting
Vue.filter('formatDate', (value: string) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY hh:mm')
  }
})

new Vue({
  router,
  store,
  components: { App },
  template: '<App/>',
  render: h => h(App)
}).$mount('#app')
