import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
// import axios from 'axios'
import axios from 'axios'
import { Market } from './market'
import { Product } from './product'

export interface Permission {
  key: string,
  name: string,
  description: string
}
export interface Role {
  key: string,
  name: string,
  permissions: Permission[],
  markets: Market[],
  products: Product[]
}

@Module
export default class RoleStore extends VuexModule {
  apiUrlRoot: string = '/roles'
  roles: Role[] = []
  allPermissions: Permission[] = []

  @Mutation
  SET_ROLES (roles: Role[]) {
    this.roles = roles
  }

  @Action({ commit: 'SET_ROLES' })
  async loadRoles () {
    const result = await axios.get(`${this.apiUrlRoot}`, { withCredentials: true })
    const data: Role[] = result.data
    return data
  }

  @Mutation
  SET_PERMISSIONS (permissions: Permission[]) {
    this.allPermissions = permissions
  }

  @Action({ commit: 'SET_PERMISSIONS' })
  async loadPermissions () {
    const result = await axios.get('/permissions', { withCredentials: true })
    const data: Permission[] = result.data
    return data
  }

  @Action async addRole () {
    const result = await axios.post(`${this.apiUrlRoot}`, { name: 'New role', description: '' }, { withCredentials: true })
    const data: Role = result.data
    return data
  }

  @Action async saveRole (roleToSave: Role) {
    const result = await axios.post(`${this.apiUrlRoot}`, roleToSave, { withCredentials: true })
    return result
  }

  @Action async deleteRole (roleToDelete: Role) {
    const result = await axios.delete(`${this.apiUrlRoot}/${roleToDelete.key}`, { withCredentials: true })
    return result
  }

  get getRoles () {
    return this.roles
  }

  get getRoleByKey () {
    return (key: string) => {
      return this.roles.find(role => role.key === key) || {
        name: '',
        description: '',
        permissions: [],
        markets: [],
        products: []
      }
    }
  }

  get getPermissions () {
    return this.allPermissions
  }

  @Action async addPermission (newPermission: Permission) {
    const result = await axios.post('/permissions', newPermission, { withCredentials: true })
    const data: Permission = result.data
    return data
  }

  @Action async deletePermission (permissionToDeleteKey: string) {
    const result = await axios.delete(`/permissions/${permissionToDeleteKey}`, { withCredentials: true })
    return result
  }
}
