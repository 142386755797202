

























import { Component, Vue } from 'vue-property-decorator'
import HeaderNav from '@/components/header/HeaderNav.vue' // @ is an alias to /src
import MarketSelector from '@/components/header/MarketSelector.vue' // @ is an alias to /src
import MainNav from '@/components/header/MainNav.vue' // @ is an alias to /src

@Component({
  components: {
    HeaderNav,
    MarketSelector,
    MainNav
  }
})
export default class Header extends Vue {
  marketSelectorVisibility: boolean = false

  get isAuthenticated () {
    return this.$store.getters.isAuthenticated
  }

  show (visible: boolean) {
    this.marketSelectorVisibility = visible
  }
}
