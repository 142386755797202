import 'intersection-observer'
import Vue from 'vue'

const instances = new WeakMap()

const _createObserver = (el: any, vnode: any, modifiers: any, callback: any) => {
  const observer = new IntersectionObserver(entries => {
    const entry = entries[0]
    if (entry.isIntersecting) {
      callback()
      if (modifiers.once) {
        _disconnectObserver(observer, el)
      }
    }
  })

  // Observe when element is inserted in DOM
  vnode.context.$nextTick(() => observer.observe(el))

  return observer
}
const _disconnectObserver = (observer: any, el: any) => {
  if (observer) observer.disconnect()
}

const bind = (el: any, { value, modifiers }: any, vnode: any) => {
  if (typeof (window as any).IntersectionObserver === 'undefined') {
    console.warn('IntersectionObserver API is not available in your browser.')
  } else {
    const observer = _createObserver(el, vnode, modifiers, () => {
      const callback = value
      if (typeof callback === 'function') callback()
    })
    instances.set(el, { observer })
  }
}

const update = (el: any, { value, oldValue }: any, vnode: any) => {
  if (value === oldValue) return

  const { observer } = instances.get(el)
  _disconnectObserver(observer, el)
  bind(el, { value, modifiers: null }, vnode)
}

const unbind = (el: any) => {
  if (instances.has(el)) {
    const { observer } = instances.get(el)
    _disconnectObserver(observer, el)
    instances.delete(el)
  }
}
Vue.directive('visible', {
  bind,
  update,
  unbind
})

// export default Vue.directive('visible', {
//   bind,
//   update,
//   unbind
// } as any)
