






















import { SlideDeck } from '@/store/modules/slideDeck'
import { Product } from '@/store/modules/product'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ChooseSlideDeck extends Vue {
  @Prop() product!: Product

  loadSlideDeck (slideDeck: SlideDeck) {
    this.$router.push({ name: 'summary-slides', params: { productKey: this.product.key, slideDeckKey: slideDeck.key } })
    this.$bvModal.hide('choose-slide-deck-modal')
  }
}
