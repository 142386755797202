import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import axios from 'axios'
import { Region } from './region'

export interface Market {
  key: string,
  name: string,
  region: Region
}

@Module
export default class MarketStore extends VuexModule {
  apiUrlRoot: string = '/markets'
  markets: Market[] = []

  @Mutation
  SET_MARKETS (markets: Market[]) {
    this.markets = markets
  }

  @Action({ commit: 'SET_MARKETS' })
  async loadMarkets () {
    const result = await axios.get(this.apiUrlRoot, { withCredentials: true })
    const data: Market[] = result.data
    return data
  }

  @Action async addMarket (newMarket: Market) {
    const result = await axios.post(`${this.apiUrlRoot}`, newMarket, { withCredentials: true })
    const data: Market = result.data
    return data
  }

  @Action async saveMarket (marketToSave: Market) {
    const result = await axios.post(`${this.apiUrlRoot}`, marketToSave, { withCredentials: true })
    return result
  }

  @Action async deleteMarket (marketToDelete: Market) {
    const result = await axios.delete(`${this.apiUrlRoot}/${marketToDelete.key}`, { withCredentials: true })
    return result
  }

  get getMarkets () {
    return this.markets
  }

  get getMarketByKey () {
    return (key: string) => {
      return this.markets.find(market => market.key === key) || {
        name: ''
      }
    }
  }
}
