import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import jwtDecode from 'jwt-decode'
import axios from 'axios'

@Module
export default class AuthStore extends VuexModule {
  token?: string = localStorage.getItem('user-token') || undefined
  isNonSSO: boolean = false

  @Mutation
  AUTH_SUCCESS (data: any) {
    if (data && data.token) {
      this.token = data.token
      localStorage.setItem('user-token', data.token)
      axios.defaults.headers.common = { Authorization: `Bearer ${this.token}` }
    }
  }

  @Mutation
  AUTH_ERROR () {
    if (this.token && this.token.length) {
      localStorage.removeItem('user-token')
      this.token = undefined
    }
  }

  @Mutation
  NON_SSO () {
    this.isNonSSO = true
  }

  @Action({ commit: 'AUTH_SUCCESS' })
  loginSuccess (data: any) {
    return data
  }

  @Action({ commit: 'AUTH_ERROR' })
  loginFailed () {
    return null
  }

  @Action({ commit: 'AUTH_ERROR' })
  async logout () {
    if (this.token) {
      await axios.post('/logout', { withCredentials: true })
    }
    return null
  }

  @Action({ commit: 'AUTH_SUCCESS' })
  async setSelectedMarket (marketKey: string) {
    const response = await axios.post('/update-selected-market', { userKey: (this.getUserObj as any).key, selectedMarketKey: marketKey }, { withCredentials: true })
    return response ? response.data : null
  }

  @Action({ commit: 'AUTH_SUCCESS' })
  async fetchNewAccessToken () {
    const response = await axios.get('/token-refresh', { withCredentials: true })
    return response ? response.data : null
  }

  @Action({ commit: 'AUTH_ERROR' })
  async clearAccessToken () {
    return true
  }

  @Action({ commit: 'NON_SSO' })
  async setUserIsNonSSO () {
    return true
  }

  get isAuthenticated () {
    return !!this.token
  }

  get userIsNonSSO () {
    return this.isNonSSO
  }

  get getUserObj () {
    if (!this.token) {
      return null
    }
    return jwtDecode(this.token)
  }

  get getUserPermissions () {
    if (!this.token) {
      return []
    }
    const user: any = jwtDecode(this.token)
    return user ? user.permissions || [] : []
  }

  get getSelectedMarket () {
    if (!this.token) {
      return ''
    }
    const user: any = jwtDecode(this.token)
    return user ? user.selectedMarket || '' : ''
  }

  get getToken () {
    return this.token
  }
}
