






import { Component, Vue } from 'vue-property-decorator'
import { EventBus } from '@/utils/event-bus'

@Component
export default class Footer extends Vue {
  active: boolean = false
  canHideOnClick = false

  created () {
    EventBus.$on('show-loader', (customMinTime: number) => {
      this.active = true
      this.canHideOnClick = false
      setTimeout(() => {
        this.canHideOnClick = true
      }, customMinTime || 5000)
    })
    EventBus.$on('hide-loader', () => {
      this.active = false
    })
  }

  clicked () {
    if (!this.canHideOnClick) {
      return
    }
    this.active = false
  }
}
