import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module
export default class ProductStylesStore extends VuexModule {
    styles:any = {}

    @Mutation
    SET_STYLES (product: any) {
      console.log(product)
      this.styles = product.brandStyles
    }

    get getStyle1 () {
      return {
        color: this.styles.brandStyle1
      }
    }

    get getStyle2 () {
      return {
        color: this.styles.brandStyle2
      }
    }

    get getStyle3 () {
      return {
        color: this.styles.brandStyle3
      }
    }

    get getStyle4 () {
      return {
        color: this.styles.brandStyle4
      }
    }

    get getStyle5 () {
      return {
        color: this.styles.brandStyle5
      }
    }

    get getStyle6 () {
      return {
        color: '#fff'
      }
    }

    get getBGStyle1 () {
      return {
        'background-color': this.styles.brandStyle1
      }
    }

    get getBGStyle2 () {
      return {
        'background-color': this.styles.brandStyle2
      }
    }

    get getBGStyle3 () {
      return {
        'background-color': this.styles.brandStyle3
      }
    }

    get getBGStyle4 () {
      return {
        'background-color': this.styles.brandStyle4
      }
    }

    get getBGStyle5 () {
      return {
        'background-color': this.styles.brandStyle5
      }
    }

    get getBGStyleRGBA () {
      return (color: any, opacity:number) => {
        color = color['background-color'].replace('#', '')
        const r = parseInt(color.substring(0, 2), 16)
        const g = parseInt(color.substring(2, 4), 16)
        const b = parseInt(color.substring(4, 6), 16)
        return {
          'background-color': `rgba(${r}, ${g}, ${b}, ${opacity / 100})`
        }
      }
    }
}
