









import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/header/Header.vue'
import Footer from '@/components/Footer.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import router from './router'
import axios from 'axios'
import store from './store/store'
import { EventBus } from './utils/event-bus'

@Component({
  components: {
    Header,
    Footer,
    LoadingSpinner
  }
})
export default class App extends Vue {
  isAlreadyFetchingAccessToken: boolean = false
  subscribers: Function[] = []

  get currentUserKey () {
    return store.getters.getUserObj.key
  }

  onAccessTokenFetched (accessToken: string) {
    this.subscribers = this.subscribers.filter((callback: Function) => callback(accessToken))
  }

  addSubscriber (callback: Function) {
    this.subscribers.push(callback)
  }

  setupRouterGuards () {
    const _showLoaderForRouteNames: string[] = ['gvd', 'summary-slides', 'resources', 'gvd-admin', 'summary-slides-admin']
    router.beforeEach((to, from, next) => {
      if (to.name && _showLoaderForRouteNames.includes(to.name) && (from.name && !_showLoaderForRouteNames.includes(from.name))) {
        EventBus.$emit('show-loader', 1000)
      }
      next()
    })
    router.afterEach((to, from) => {
      if (to.name && _showLoaderForRouteNames.includes(to.name)) {
        EventBus.$emit('hide-loader')
      }
    })
  }

  setupAuthGuards () {
    // intercept any 301: unauthorized and redirect to login
    axios.interceptors.response.use((response: any) => {
      return response
    }, (error: any) => {
      const { config, response: { status } } = error
      const originalRequest = config
      const _isTokenRefreshRequest = originalRequest.url.includes('token-refresh')

      // console.log(originalRequest.url, status)
      if (status === 401) {
        // console.log(`401 at '${originalRequest.url}'`)
        if (_isTokenRefreshRequest) {
          // console.log('token-refresh:401')
          return Promise.reject(error)
        }
        if (this.$route.name === 'login' && originalRequest.url.includes('login')) {
          return Promise.reject(error)
        }
        if (!this.isAlreadyFetchingAccessToken) {
          // console.log('start fetch of access token')
          this.isAlreadyFetchingAccessToken = true
          store.dispatch('fetchNewAccessToken').then((accessToken: any) => {
            // console.log('accessToken', accessToken)
            // handler for if api fails entirely:
            if (accessToken === null) {
              this.subscribers = []
              this.isAlreadyFetchingAccessToken = false
              // console.log('accessToken === null')
              router.push({ name: 'login', query: { redirect: this.$route.path } })
              Promise.reject(error)
              return
            }
            // on successfully getting token:
            this.isAlreadyFetchingAccessToken = false
            this.onAccessTokenFetched(accessToken.token)
            this.subscribers = []
          }).catch(() => {
            this.subscribers = []
            this.isAlreadyFetchingAccessToken = false
            // console.warn('fetchNewAccessToken fail')

            // if we're arriving at the login route then don't bother doing anything here
            if (this.$route.name === 'login') {
              return Promise.reject(error)
            }

            // if we think the user logged in with traditional auth then push them to the login page
            if (store.getters.userIsNonSSO) {
              router.push({ name: 'login', query: { redirect: this.$route.path } })
              this.$bvModal.msgBoxOk('You\'ve been logged out due to inactivity. Please log back in to continue.', {
                title: 'Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'error',
                centered: true
              })
              EventBus.$emit('hide-loader')
              return Promise.reject(error)
            }

            // standard SSO users need to be redirected
            this.$bvModal.msgBoxOk('Session expired - You will now be redirected to log in.', {
              size: 'sm',
              buttonSize: 'sm',
              okTitle: 'OK',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(async value => {
              if (process.env.VUE_APP_SIMPLE_AUTH_ONLY && process.env.VUE_APP_SIMPLE_AUTH_ONLY === 'true') {
                router.push({ name: 'login', query: { redirect: this.$route.path } })
              } else {
                router.push({ name: 'sso' })
              }
            })
            return Promise.reject(error)
          })
        }
        // console.log('return promise retryOriginalRequest, which resolves when new token fetched', originalRequest.url)
        const retryOriginalRequest = new Promise((resolve) => {
          this.addSubscriber((accessToken: string) => {
            originalRequest.headers.Authorization = 'Bearer ' + accessToken
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }
      // handle other errors
      if (!error.response && error.message) {
        this.$bvModal.msgBoxOk(`Error: ${error.message}`, {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'error',
          centered: true
        })
        EventBus.$emit('hide-loader')
        return Promise.reject(error)
      }
      // if (error.response.status === 401) {
      //   store.dispatch('logout')
      //   router.push('/login')
      //   return Promise.reject(error)
      // }
      // console.log(JSON.stringify(error, null, 2))
      // console.error(error.response)
      const _message = (`API error: ${error.response.statusText ? error.response.statusText : error}`)
      this.$bvModal.msgBoxOk(_message, {
        title: `Error (${error.response.status})`,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'error',
        centered: true
      })
      EventBus.$emit('hide-loader')
      return error
      // return Promise.reject(error)
    })
  }

  setupAxiosDefaults () {
    axios.interceptors.request.use((config) => {
      if (this.$store.getters.getActiveProduct) {
        config.headers['active-product-key'] = `${this.$store.getters.getActiveProduct.key}`
      }
      return config
    })
    // axios defaults + jwt config
    axios.defaults.baseURL = `${process.env.VUE_APP_API_HOST || 'http://localhost:3305'}/api/v1/`
    axios.defaults.headers.common.Authorization = `Bearer ${this.$store.getters.getToken}`
    // 'Access-Control-Allow-Credentials': true
    axios.defaults.withCredentials = true
  }

  created () {
    this.setupRouterGuards()
    this.setupAuthGuards()
    this.setupAxiosDefaults()
  }
}
