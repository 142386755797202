import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSearchPlus,
  faSearchMinus,
  faSearch,
  faArrowsH,
  faArrows,
  faFileDownload,
  faFile,
  faFileUpload,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faTrash,
  faLink,
  faPrintSearch,
  faPrint,
  faPlus,
  faMinus,
  faShoppingCart,
  faLockAlt,
  faIndent,
  faCheck,
  faWrench,
  faLock,
  faFilePdf,
  faFileWord,
  faFilePowerpoint,
  faInfoCircle,
  faFilePlus
} from '@fortawesome/pro-light-svg-icons'
import {
  faChevronCircleRight,
  faChevronCircleDown,
  faChevronRight,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faTimes,
  faFileAlt
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const fonts = [
  faSearchPlus,
  faSearchMinus,
  faSearch,
  faArrowsH,
  faArrows,
  faFileDownload,
  faFileUpload,
  faFile,
  faFilePlus,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faChevronCircleRight,
  faChevronCircleDown,
  faChevronRight,
  faTrash,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faLink,
  faPrintSearch,
  faPrint,
  faPlus,
  faMinus,
  faShoppingCart,
  faTimes,
  faFileAlt,
  faFilePowerpoint,
  faFilePdf,
  faFileWord,
  faWrench,
  faLockAlt,
  faIndent,
  faCheck,
  faLock,
  faInfoCircle
]

function initFontAwesome () {
  for (let i = 0; i < fonts.length; i++) {
    library.add(fonts[i])
  }
  Vue.component('font-awesome-icon', FontAwesomeIcon)
}
initFontAwesome()
