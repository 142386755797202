import Vue from 'vue'
import Router, { Route } from 'vue-router'
import Home from './views/Home.vue'
import { authCheck } from './utils/auth'
import store from './store/store'
import { EventBus } from './utils/event-bus'

Vue.use(Router)

const _genericRoutes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: authCheck
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    beforeEnter: authCheck
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter (to: Route, from: Route, next: Function) {
      store.dispatch('logout')
      next({ name: 'home' })
    }
  },
  {
    path: '/sso',
    name: 'sso',
    component: () => import(/* webpackChunkName: "sso" */ './views/SSO.vue')
  },
  {
    path: '/authenticate',
    name: 'authenticate',
    component: Home
  },
  {
    path: '/login/:token',
    name: 'login',
    component: Home,
    beforeEnter (to: Route, from: Route, next: Function) {
      store.dispatch('loginSuccess', { token: to.params.token }).then(() => {
        const _user = store.getters.getUserObj
        if (!_user.activated) {
          return next('/awaiting-activation')
        }
        EventBus.$emit('market-selected')
        store.dispatch('loadProducts', store.getters.getSelectedMarket).then(() => {
          next({ name: 'home' })
        })
      })
    }
  },
  {
    path: '/forgotten-password',
    name: 'forgotten-password',
    component: () => import(/* webpackChunkName: "login" */ './views/ForgottenPassword.vue')
    // beforeEnter: authCheck
  },
  {
    path: '/set-password/:email/:token',
    name: 'set-password',
    component: () => import(/* webpackChunkName: "login" */ './views/SetPassword.vue')
    // beforeEnter: authCheck
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ './views/Account.vue'),
    beforeEnter: authCheck
  },
  {
    path: '/awaiting-activation',
    name: 'awaiting-activation',
    component: () => import(/* webpackChunkName: "account" */ './views/AwaitingActivation.vue'),
    beforeEnter: authCheck
  },
  {
    path: '/locked/:key',
    name: 'locked',
    component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
    beforeEnter: authCheck
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import(/* webpackChunkName: "faqs" */ './views/FAQs.vue'),
    beforeEnter: authCheck
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "faqs" */ './views/Contact.vue'),
    beforeEnter: authCheck
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "product" */ './views/404.vue')
  }
]

const _registerRoute = [
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "login" */ './views/Register.vue')
    // beforeEnter: authCheck
  }
]

const _productRoutes = [
  {
    path: '/product/:productKey',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ './views/products/Product.vue'),
    beforeEnter: authCheck
  },
  {
    path: '/product/:productKey/gvd/',
    name: 'gvd-catch',
    component: () => import(/* webpackChunkName: "gvd" */ './views/products/gvds/Gvd.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: ['gvd.view']
    }
  },
  {
    path: '/product/:productKey/gvd/:gvdKey',
    name: 'gvd',
    component: () => import(/* webpackChunkName: "gvd" */ './views/products/gvds/Gvd.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: ['gvd.view']
    }
  },
  {
    path: '/product/:productKey/summary-slides/',
    name: 'summary-slides-catch',
    component: () => import(/* webpackChunkName: "summary-slides" */ './views/products/summary-slides/SlideDeck.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: []
    }
  },
  {
    path: '/product/:productKey/summary-slides/:slideDeckKey',
    name: 'summary-slides',
    component: () => import(/* webpackChunkName: "summary-slides" */ './views/products/summary-slides/SlideDeck.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: []
    }
  },
  {
    path: '/product/:productKey/resources',
    name: 'resources',
    component: () => import(/* webpackChunkName: "resources" */ './views/products/resources/Resources.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: []
    }
  }
]

const _adminRoutes = [
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ './views/admin/Admin.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: ['admin.view']
    }
  },
  {
    path: '/admin/users',
    name: 'manage-users',
    component: () => import(/* webpackChunkName: "admin/users" */ './views/admin/users/Users.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: ['admin.view']
    }
  },
  {
    path: '/admin/users/:userKey',
    name: 'manage-user',
    component: () => import(/* webpackChunkName: "admin/user" */ './views/admin/users/User.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: ['admin.view']
    }
  },
  {
    path: '/admin/roles',
    name: 'manage-roles',
    component: () => import(/* webpackChunkName: "admin/roles" */ './views/admin/roles/Roles.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: ['admin.view']
    }
  },
  {
    path: '/admin/resources',
    name: 'manage-resources',
    component: () => import(/* webpackChunkName: "admin/resources" */ './views/admin/resources/Resources.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: ['admin.resources.view']
    }
  },
  {
    path: '/admin/markets',
    name: 'manage-markets',
    component: () => import(/* webpackChunkName: "admin/markets" */ './views/admin/markets/Markets.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: ['admin.markets.view']
    }
  },
  {
    path: '/admin/product/:productKey',
    name: 'product-admin',
    component: () => import(/* webpackChunkName: "admin/product" */ './views/admin/products/Product.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: ['admin.product.view']
    }
  },
  {
    path: '/admin/product/:productKey/gvd/:gvdKey',
    name: 'gvd-admin',
    component: () => import(/* webpackChunkName: "admin/gvd" */ './views/admin/products/gvds/Gvd.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: ['admin.gvd.view']
    }
  },
  {
    path: '/admin/product/:productKey/summary-slides/:slideDeckKey',
    name: 'summary-slides-admin',
    component: () => import(/* webpackChunkName: "admin/summary-slides" */ './views/admin/products/summary-slides/SlideDeck.vue'),
    beforeEnter: authCheck,
    meta: {
      requiredPermissions: ['admin.slideDeck.view']
    }
  }
]

let _routes = [
  ..._genericRoutes,
  ..._productRoutes,
  ..._adminRoutes
]

if (!process.env.VUE_APP_DISABLE_REGISTRATION || (process.env.VUE_APP_DISABLE_REGISTRATION === 'false')) {
  _routes = [
    ..._routes,
    ..._registerRoute
  ]
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: _routes
})
