








































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Home extends Vue {
  get isAuthenticated () {
    return this.$store.getters.isAuthenticated
  }

  mounted () {
    const _browserDetect = (this as any).$browserDetect
    if (_browserDetect.isIE && _browserDetect.meta.version === '11' && this.$store.getters.isAuthenticated) {
      const _msgNode = this.$createElement('div', [
        this.$createElement('p', { class: ['mb-1'] }, ['Unforunately this browser is not supported by the Value Platform.']),
        this.$createElement('p', { class: ['mb-1'] }, ['Please use an alternative browser.']),
        this.$createElement('p', { class: ['m-0'] }, ['For the best experience we recommend Google Chrome.'])
      ])
      this.$bvModal.msgBoxOk([_msgNode], {
        size: 'sm',
        buttonSize: 'md',
        okTitle: 'OK',
        titleClass: 'w-100 text-center',
        okVariant: 'default',
        footerClass: 'p-2 text-center',
        bodyClass: 'text-center',
        noCloseOnBackdrop: true,
        noCloseOnEscape: true,
        hideHeaderClose: true,
        title: 'Browser not supported',
        centered: true
      }).then(() => {
        this.$router.push({ name: 'logout' })
      })
    }
  }
}
