
import store from '@/store/store'
import { Route } from 'vue-router'

// const ifNotAuthenticated = (to: Route, from: Route, next: Function) => {
//   if (!store.getters.isAuthenticated) {
//     next()
//     return
//   }
//   next('/')
// }

export const authCheck = (to: Route, from: Route, next: Function) => {
  // console.log(store.getters.isAuthenticated)
  if (!store.getters.isAuthenticated) {
    if (store.getters.userIsNonSSO) {
      return next('/login')
    }
    return next('/sso')
  }
  const _user = store.getters.getUserObj
  if (!_user.activated && (to.name !== 'account' && to.name !== 'awaiting-activation')) {
    return next('/awaiting-activation')
  }
  // if there are no required permissions or the user has all required permissions
  // console.log(hasPermissions(to.meta.requiredPermissions))
  if (to && to.meta) {
    if (!to.meta.requiredPermissions || hasPermissions(to.meta.requiredPermissions)) {
      if (store.getters.isAuthenticated) {
        store.dispatch('fetchNewAccessToken').then((accessToken: any) => {
        }).catch((e) => {
          // handle reload where API server is not yet initialised.
          if (!e.message.includes('ERR_ACTION_ACCESS_UNDEFINED')) {
            if (store.getters.userIsNonSSO) {
              return next('/login')
            }
            return next('/sso')
          }
        })
      }

      return next()
    }
  }
  // otherwise stay put
  // todo: handle this somehow?
  console.warn('No permission for this route')
  return next(false)
}

export const hasPermissions = (requiredPermissions: string[]): boolean => {
  if (!requiredPermissions) {
    return true
  }
  return requiredPermissions.every((rp: string) => {
    return store.getters.getUserPermissions.some((p: any) => {
      return p === rp
    })
  })
}
