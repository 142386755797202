import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import axios from 'axios'
import { Gvd } from './gvd'
import { SlideDeck } from './slideDeck'
import store from '../store'

export interface Product {
  key: string,
  name: string,
  gvds: Gvd[],
  disabled: boolean,
  slideDecks: SlideDeck[]
}

@Module
export default class ProductStore extends VuexModule {
  apiUrlRoot: string = '/products'
  products: Product[] = []
  product: Product = { key: '', name: '', gvds: [], slideDecks: [], disabled: false }

  @Mutation
  SET_PRODUCTS (products: Product[]) {
    this.products = products
  }

  @Mutation
  SET_ACTIVE_PRODUCT (product: Product) {
    store.commit('SET_STYLES', product)

    this.product = product || { key: '', name: '', gvds: [], slideDecks: [], disabled: false }
  }

  @Action({ commit: 'SET_PRODUCTS' })
  async loadProducts (marketKey?: string) {
    let result = null
    if (marketKey) {
      result = await axios.get(`${this.apiUrlRoot}/market/${marketKey}`, { withCredentials: true })
    } else {
      result = await axios.get(`${this.apiUrlRoot}`, { withCredentials: true })
    }
    const data: Product[] = result.data
    return data
  }

  @Action({ commit: 'SET_PRODUCTS' })
  async loadProductsIncDisabled (marketKey?: string) {
    let result = null
    if (marketKey) {
      result = await axios.get(`${this.apiUrlRoot}/market/${marketKey}/disabled`, { withCredentials: true })
    } else {
      result = await axios.get(`${this.apiUrlRoot}/disabled`, { withCredentials: true })
    }
    const data: Product[] = result.data
    return data
  }

  @Action({ commit: 'SET_PRODUCTS' })
  async loadProductsAdmin (marketKey?: string) {
    let result = null
    if (marketKey) {
      result = await axios.get(`${this.apiUrlRoot}/market/admin/${marketKey}`, { withCredentials: true })
    } else {
      result = await axios.get(`${this.apiUrlRoot}/admin`, { withCredentials: true })
    }
    const data: Product[] = result.data
    return data
  }

  @Action({ commit: 'SET_PRODUCTS' })
  async loadProductsAdminIncDisabled (marketKey?: string) {
    let result = null
    if (marketKey) {
      result = await axios.get(`${this.apiUrlRoot}/market/admin/${marketKey}/disabled`, { withCredentials: true })
    } else {
      result = await axios.get(`${this.apiUrlRoot}/admin/disabled`, { withCredentials: true })
    }
    const data: Product[] = result.data
    return data
  }

  @Action({ commit: 'SET_ACTIVE_PRODUCT' })
  async loadProduct ({ productKey, marketKey }: any) {
    const result = await axios.get(`${this.apiUrlRoot}/${productKey}/${marketKey}`, { withCredentials: true })

    if (result.status === 200) {
      const data: Product = result.data
      return data
    }
    return null
  }

  @Action({ commit: 'SET_ACTIVE_PRODUCT' })
  async loadProductIncDisabled ({ productKey, marketKey }: any) {
    const result = await axios.get(`${this.apiUrlRoot}/${productKey}/${marketKey}/disabled`, { withCredentials: true })

    if (result.status === 200) {
      const data: Product = result.data
      return data
    }
    return null
  }

  @Action({ commit: 'SET_ACTIVE_PRODUCT' })
  async loadProductAdmin ({ productKey, marketKey }: any) {
    const result = await axios.get(`${this.apiUrlRoot}/drafts/${productKey}/${marketKey}`, { withCredentials: true })

    if (result.status === 200) {
      const data: Product = result.data
      return data
    }
    return null
  }

  @Action({ commit: 'SET_ACTIVE_PRODUCT' })
  async loadProductAdminIncDisabled ({ productKey, marketKey }: any) {
    const result = await axios.get(`${this.apiUrlRoot}/drafts/${productKey}/${marketKey}/disabled`, { withCredentials: true })

    if (result.status === 200) {
      const data: Product = result.data
      return data
    }
    return null
  }

  @Action async saveProduct (productToSave: Product) {
    const result = await axios.post(`${this.apiUrlRoot}`, productToSave, { withCredentials: true })
    return result
  }

  @Action async addNewProductMarket ({ productKey, marketKey }: any) {
    const result = await axios.post(`${this.apiUrlRoot}/add-market/`, { productKey, marketKey }, { withCredentials: true })
    return result
  }

  @Action async deleteProductMarket ({ productKey, marketKey }: any) {
    const result = await axios.post(`${this.apiUrlRoot}/delete-market/`, { productKey, marketKey }, { withCredentials: true })
    return result
  }

  @Action async addNewProduct (newProduct: any) {
    const result = await axios.post(`${this.apiUrlRoot}`, newProduct, { withCredentials: true })
    return result
  }

  get getActiveProduct () {
    return this.product
  }

  get getProducts () {
    return this.products
  }

  get getProductByKey () {
    return (key: string) => {
      return this.products.find(product => product.key === key)
    }
  }
}
