






















import { Gvd } from '@/store/modules/gvd'
import { Product } from '@/store/modules/product'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ChooseGvd extends Vue {
  @Prop() product!: Product

  loadGvd (gvd: Gvd) {
    this.$router.push({ name: 'gvd', params: { productKey: this.product.key, gvdKey: gvd.key } })
    this.$bvModal.hide('choose-gvd-modal')
  }
}
