


























































































import { Component, Vue } from 'vue-property-decorator'
import { EventBus } from '@/utils/event-bus'
import { Product } from '@/store/modules/product'
import ChooseGvdModal from './ChooseGvd.modal.vue'
import ChooseSlideDeckModal from './ChooseSlideDeck.modal.vue'

@Component({
  components: {
    ChooseGvdModal,
    ChooseSlideDeckModal
  }
})
export default class MainNav extends Vue {
  products: Product[] = []
  selectedProduct: Product = {} as Product

  get isAuthenticated () {
    return this.$store.getters.isAuthenticated
  }

  get showAdmin () {
    return this.$store.getters.getUserPermissions.includes('admin.view')
  }

  get isInProduct () {
    const routesToMatch = ['product', 'gvd', 'summary-slides', 'resources']
    return routesToMatch.includes(this.$route.name || '')
  }

  goToGvd (product: Product) {
    if (product.gvds.length === 1) {
      this.$router.push({ name: 'gvd', params: { productKey: product.key, gvdKey: product.gvds[0].key } }).catch(() => {})

      return
    }

    this.selectedProduct = product
    this.$bvModal.show('choose-gvd-modal')
  }

  getButtonBackground () {
    if (this.isInProduct) {
      return this.$store.getters.getBGStyle3
    }
  }

  goToSlideDeck (product: Product) {
    if (product.slideDecks.length === 1) {
      this.$router.push({ name: 'summary-slides', params: { productKey: product.key, slideDeckKey: product.slideDecks[0].key } }).catch(() => {})
      return
    }

    this.selectedProduct = product
    this.$bvModal.show('choose-slide-deck-modal')
  }

  goToProduct (product: Product) {
    this.$router.push({ name: 'product', params: { productKey: product.key } }).catch(() => {})
  }

  noProductAssets (product: Product) {
    return !this.hasResources(product.key) && !product.gvds.length && !product.slideDecks.length
  }

  hasResources (productKey: string) {
    return this.$store.getters.getResourcesForProductKey(productKey).length
  }

  isActiveProduct (key: string) {
    const routesToMatch = ['product', 'gvd', 'summary-slides', 'resources']
    return routesToMatch.includes(this.$route.name || '') && (this.$route.params.productKey || '') === key
  }

  marketSelected (preventRedirect: boolean) {
    if (!this.$store.getters.isAuthenticated) {
      return
    }

    this.$store.dispatch(this.$route.path.includes('admin') ? 'loadProductsAdmin' : 'loadProducts', this.$store.getters.getSelectedMarket).then(() => {
      this.products = this.$store.getters.getProducts
        .filter((p: Product) => !p.disabled)
        .sort((a: Product, b: Product) => {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
        })
      this.$store.dispatch('loadResourcesKeysForMarket', this.$store.getters.getSelectedMarket)
      if (preventRedirect) {
        return
      }
      if (this.$route.path.includes('product')) {
        if (this.$route.path.includes('admin')) {
          // eslint-disable-next-line node/handle-callback-err
          this.$router.push({ name: 'product-admin', params: { productKey: this.$route.params.productKey } }).catch(err => {})
          return
        }
        // eslint-disable-next-line node/handle-callback-err
        this.$router.push({ name: 'product', params: { productKey: this.$route.params.productKey } }).catch(err => {})
      }
    })
  }

  created () {
    this.marketSelected(true)
    EventBus.$on('market-selected', this.marketSelected)

    // this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
    //   // console.log(bvEvent)
    //   // if (!$(bvEvent.target).closest('.dropdown-menu').length) {
    //   //   bvEvent.preventDefault()
    //   // }
    // })
    // this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
    //   // console.log('test')
    // })
  }
}
