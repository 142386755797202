import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import axios from 'axios'
import { Resource, GvdResourceNumber } from './resource'
import { Product } from './product'
import { Market } from './market'
import store from '@/store/store'

export interface Gvd {
  key: string,
  title: string,
  description: string,
  disabled: boolean,
  semVersion: string,
  pdfFilename?: string,
  docxFilename?: string,
  // eslint-disable-next-line no-use-before-define
  gvdSections: GvdSection[],
  product: Product,
  markets: Market[]
  hasChanges: boolean,
  isDraft: boolean,
  gvdResourceNumbers: GvdResourceNumber[],
  draftEntity?: Gvd,
  publishedEntity?: Gvd,
}
export interface GvdPage {
  key: string,
  title: string,
  orderIdx: number,
  resources: Resource[],
  // eslint-disable-next-line no-use-before-define
  gvdSection: GvdSection,
  pageNo: number,
  publishedKey: string
}
export interface GvdSection {
  key: string,
  title: string,
  orderIdx: number,
  gvdPages: GvdPage[]
  gvd: Gvd,
}
export interface GvdUpdate {
  key: string,
  semVersion: string,
  summary: string,
  description: string,
  createdAt: string
}

@Module
export default class GvdStore extends VuexModule {
  apiUrlRoot: string = '/gvds'
  gvds: Gvd[] = []
  // active gvd
  gvd: Gvd = {
    key: '',
    title: '',
    gvdSections: [],
    markets: []
  } as any

  activeGvdSections?: GvdSection[]
  activeGvdPages?: GvdPage[]
  activeGvdSection?: GvdSection
  activeGvdPage?: GvdPage
  activeGvdChangeLog?: GvdUpdate[]

  // MUTATIONS

  @Mutation
  SET_GVDS (gvds: Gvd[]) {
    this.gvds = gvds
  }

  @Mutation
  SET_ACTIVE_GVD (gvd: Gvd) {
    this.gvd = gvd
  }

  @Mutation
  SET_ACTIVE_GVD_SECTIONS (gvdSections: GvdSection[]) {
    this.activeGvdSections = gvdSections
  }

  @Mutation
  SET_ACTIVE_GVD_PAGES (gvdPages: GvdPage[]) {
    this.activeGvdPages = gvdPages
  }

  @Mutation
  SET_ACTIVE_GVD_SECTION (gvdSection: GvdSection) {
    this.activeGvdSection = gvdSection
  }

  @Mutation
  SET_ACTIVE_GVD_PAGE (gvdPage: GvdPage) {
    this.activeGvdPage = gvdPage
  }

  @Mutation
  SET_ACTIVE_GVD_CHANGE_LOG (updates: GvdUpdate[]) {
    this.activeGvdChangeLog = updates
  }

  // ACTIONS

  @Action({ commit: 'SET_GVDS' })
  async loadGvds () {
    const result = await axios.get(this.apiUrlRoot, { withCredentials: true })

    const data: Gvd[] = result.data
    return data
  }

  @Action({ commit: 'SET_GVDS' })
  async loadGvdsByProductKey (productKey: string) {
    const result = await axios.get(`${this.apiUrlRoot}/by-product/${productKey}`, { withCredentials: true })

    const data: Gvd[] = result.data
    return data
  }

  @Action async addGvd (newGvd: Gvd) {
    const result = await axios.post(`${this.apiUrlRoot}`, newGvd, { withCredentials: true })
    const data: Gvd = result.data
    return data
  }

  @Action({ commit: 'SET_ACTIVE_GVD' })
  async loadGvd (gvdKey: string) {
    const uri: string = (process.env.MULTI_MARKET_REFERENCES === 'true' || process.env.MULTI_MARKET_REFERENCES === true) ? `${this.apiUrlRoot}/${gvdKey}` : `${this.apiUrlRoot}/${gvdKey}/market/${store.getters.getSelectedMarket}`

    const result = await axios.get(uri, { withCredentials: true })

    const gvd: Gvd = result.data

    return gvd
  }

  @Action({ commit: 'SET_ACTIVE_GVD' })
  async loadGvdAdmin (gvdKey: string) {
    const result = await axios.get(`${this.apiUrlRoot}/${gvdKey}`, { withCredentials: true })

    const gvd: Gvd = result.data
    return gvd
  }

  @Action({ commit: 'SET_ACTIVE_GVD_SECTIONS' })
  async loadGvdSections (gvdSections: GvdSection[]) {
    return gvdSections
  }

  @Action({ commit: 'SET_ACTIVE_GVD_PAGES' })
  async loadGvdPages (gvdSections: GvdSection[]) {
    let _gvdPages: GvdPage[] = []
    gvdSections.forEach((gvdSection: GvdSection) => {
      _gvdPages = _gvdPages.concat(gvdSection.gvdPages)
    })
    return _gvdPages
  }

  @Action({ commit: 'SET_ACTIVE_GVD_SECTION' })
  setActiveGvdSection (gvdSection: GvdSection) {
    return gvdSection
  }

  @Action({ commit: 'SET_ACTIVE_GVD_PAGE' })
  // @MutationAction({ mutate: ['activeGvdPage', 'activeGvdSection'] })
  setActiveGvdPage (pageNo: number) {
    let gvdPageIndex: number
    let gvdSection: GvdSection | null = null
    for (let i = 0; i < this.gvd.gvdSections.length; i++) {
      gvdSection = this.gvd.gvdSections[i]
      if (gvdSection.gvdPages.length) {
        gvdPageIndex = gvdSection.gvdPages.findIndex(page => {
          return page.pageNo === pageNo
        })
        if (gvdPageIndex >= 0) {
          return gvdSection.gvdPages[gvdPageIndex]
        }
      }
    }
  }

  @Action({ commit: 'SET_ACTIVE_GVD_CHANGE_LOG' })
  @Action async loadGvdChangeLog (gvdKey: string) {
    const result = await axios.get(`${this.apiUrlRoot}/updates/${gvdKey}`, { withCredentials: true })
    const data: GvdUpdate[] = result.data
    return data
  }

  // admin stuff

  @Action({ commit: 'SET_ACTIVE_GVD' })
  async publishGvd ({ gvd, updateDetails }: any) {
    const result = await axios.post(`${this.apiUrlRoot}/publish`, { gvd, updateDetails }, { withCredentials: true })
    const data: Gvd = result.data
    return data
  }

  @Action({ commit: 'SET_ACTIVE_GVD' })
  async updateGvd (gvd: Gvd) {
    if (gvd.hasChanges) {
      await axios.post(`${this.apiUrlRoot}/updated/${gvd.key}`, { withCredentials: true })
    }
    return gvd
  }

  @Action async saveGvd (gvdToSave: Gvd) {
    const result = await axios.post(`${this.apiUrlRoot}`, gvdToSave, { withCredentials: true })
    return result
  }

  @Action async saveGvdFile ({ key, formData } : { key: string, formData: FormData}) {
    const result = await axios.post(`${this.apiUrlRoot}/file-upload/${key}`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true })
    return result
  }

  @Action async addNewGvdSection (gvdSectionToAdd: GvdSection) {
    const result = await axios.post('/gvd-sections/', gvdSectionToAdd, { withCredentials: true })
    return result
  }

  @Action async saveGvdSection (gvdSectionToSave: GvdSection) {
    const result = await axios.post('/gvd-sections/', gvdSectionToSave, { withCredentials: true })
    return result
  }

  @Action async deleteGvdSection (sectionKeyToDelete: string) {
    const result = await axios.delete(`/gvd-sections/${sectionKeyToDelete}`, { withCredentials: true })
    return result
  }

  @Action async addNewGvdPage (gvdPageToAdd: GvdPage) {
    const result = await axios.post('/gvd-pages/', gvdPageToAdd, { withCredentials: true })
    return result
  }

  @Action async saveGvdPage (gvdPageToSave: GvdPage) {
    const result = await axios.post('/gvd-pages/', gvdPageToSave, { withCredentials: true })
    return result
  }

  @Action async deleteGvdPage (pageKeyToDelete: string) {
    const result = await axios.delete(`/gvd-pages/${pageKeyToDelete}`, { withCredentials: true })
    return result
  }

  @Action async reorderGvdSection (sectionToReorder: GvdSection) {
    const result = await axios.post('/gvd-sections/reorder', sectionToReorder, { withCredentials: true })
    return result
  }

  @Action async reorderGvdPage (pageToReorder: GvdPage) {
    const result = await axios.post('/gvd-pages/reorder', pageToReorder, { withCredentials: true })
    return result
  }

  @Action async addNewGvdMarket ({ gvdKey, marketKey }: any) {
    const result = await axios.post(`${this.apiUrlRoot}/add-market/`, { gvdKey, marketKey }, { withCredentials: true })
    return result
  }

  @Action async deleteGvdMarket ({ gvdKey, marketKey }: any) {
    const result = await axios.post(`${this.apiUrlRoot}/delete-market/`, { gvdKey, marketKey }, { withCredentials: true })
    return result
  }

  // GETTERS

  get getGvds () {
    return this.gvds
  }

  get getActiveGvd () {
    return this.gvd
  }

  get getGvdByKey () {
    return (key: string) => {
      return this.gvds.find(gvd => gvd.key === key) || null
    }
  }

  get getActiveGvdSections () {
    return this.activeGvdSections
  }

  get getActiveGvdPages () {
    return this.activeGvdPages
  }

  get getActiveGvdSection () {
    return this.activeGvdSection
  }

  get getActiveGvdPage () {
    return this.activeGvdPage
  }

  get getActiveGvdChangeLog () {
    return this.activeGvdChangeLog
  }
}
