import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
// import axios from 'axios'
import axios from 'axios'
import { Role } from './role'

export interface User {
  key: string,
  firstName: string,
  secondName: string,
  email: string,
  newPassword: string | undefined,
  roles: Role[],
  SAMLNameID: string,
  traditionalAuth: boolean,
  activated: boolean,
  locked: boolean
}

@Module
export default class UserStore extends VuexModule {
  apiUrlRoot: string = '/users'
  users: User[] = []
  user: User | null = null

  @Mutation
  SET_USERS (users: User[]) {
    this.users = users
  }

  @Mutation
  SET_USER (user: User) {
    this.user = user
  }

  @Action({ commit: 'SET_USERS' })
  async loadUsers () {
    const result = await axios.get(`${this.apiUrlRoot}`, { withCredentials: true })
    const data: User[] = result.data
    return data
  }

  @Action({ commit: 'SET_USER' })
  async loadUser (userKey: string) {
    const result = await axios.get(`${this.apiUrlRoot}/${userKey}`, { withCredentials: true })
    const data: User = result.data
    return data
  }

  @Action async saveUser (userToSave: User) {
    const result = await axios.post(`${this.apiUrlRoot}`, userToSave, { withCredentials: true })
    return result
  }

  get getUsers () {
    return this.users
  }

  get getUser () {
    return this.user
  }
}
