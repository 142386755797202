import Vue from 'vue'
import Vuex, { ActionTree, ActionContext } from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

import auth from './modules/auth'
import regions from './modules/region'
import markets from './modules/market'
import products from './modules/product'
import productStyles from './modules/productStyles'
import gvds from './modules/gvd'
import slideDecks from './modules/slideDeck'
import resources from './modules/resource'
import user from './modules/user'
import role from './modules/role'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  state: {},
  modules: {
    auth,
    regions,
    markets,
    products,
    productStyles,
    gvds,
    slideDecks,
    resources,
    user,
    role
  }
})
