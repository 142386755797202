import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
// import axios from 'axios'
import { GvdPage, Gvd } from './gvd'
import axios from 'axios'
import { Market } from './market'
import { Product } from './product'
import { SlideDeckPage, SlideDeck } from './slideDeck'
import store from '@/store/store'

export interface Resource {
  resourceTypeName: string
  key: string,
  name: string,
  description: string,
  // eslint-disable-next-line no-use-before-define
  resourceType: ResourceType | null,
  // eslint-disable-next-line no-use-before-define
  resourceTags: ResourceTag[]
  externalLink?: string,
  filename?: string,
  fileExt?: string,
  gvdPage: GvdPage | null,
  gvdPages: GvdPage[] | null,
  // eslint-disable-next-line no-use-before-define
  gvdResourceNumbers: GvdResourceNumber[],
  slideDeckPage: SlideDeckPage | null,
  slideDeckPages: SlideDeckPage[] | null,
  // eslint-disable-next-line no-use-before-define
  slideDeckResourceNumbers: SlideDeckResourceNumber[],
  markets: Market[],
  products: Product[],
  draftEntity?: Resource,
  publishedEntity?: Resource,
  toDelete: boolean,
  hasChanges: boolean,
  isDraft: boolean
}
export interface ResourceType {
  key: string,
  name: string
}
export interface ResourceTag {
  edit: boolean
  key: string,
  name: string
  // eslint-disable-next-line no-use-before-define
  resourceTagCategory: ResourceTagCategory
}
export interface ResourceTagCategory {
  edit: boolean,
  key: string,
  name: string
}
export interface GvdResourceNumber {
  number: number,
  gvd: Gvd,
  resource: Resource
}
export interface SlideDeckResourceNumber {
  number: number,
  slideDeck: SlideDeck,
  resource: Resource
}

@Module
export default class ResourceStore extends VuexModule {
  apiUrlRoot: string = '/resources'
  resourceTypes: ResourceType[] = []
  resourceTags: ResourceTag[] = []
  resourceTagCategories: ResourceTagCategory[] = []
  allResources: Resource[] = []
  allDraftResources: Resource[] = []
  allProductResources: Resource[] = []
  allDraftProductResources: Resource[] = []
  activePageResources: Resource[] = []

  // reused for both slide decks and gvds
  @Mutation
  SET_ACTIVE_PAGE_RESOURCES (activePageResources: Resource[]) {
    this.activePageResources = activePageResources
  }

  @Action({ commit: 'SET_ACTIVE_PAGE_RESOURCES' })
  loadGvdPageResources (page: GvdPage) {
    return page ? page.resources : []
  }

  // reused for both slide decks and gvds
  get getActivePageResources () {
    return this.activePageResources
  }

  // reused for both slide decks and gvds
  @Mutation
  SET_ALL_RESOURCES (resources: Resource[]) {
    this.allResources = resources
  }

  @Mutation
  SET_ALL_DRAFT_RESOURCES (resources: Resource[]) {
    this.allDraftResources = resources
  }

  @Mutation
  SET_ALL_PRODUCT_RESOURCES (resources: Resource[]) {
    this.allProductResources = resources
  }

  @Mutation
  SET_ALL_DRAFT_PRODUCT_RESOURCES (resources: Resource[]) {
    this.allDraftProductResources = resources
  }

  @Action({ commit: 'SET_ALL_RESOURCES' })
  async loadAllResources () {
    const result = await axios.get('/resources', { withCredentials: true })
    const data: Resource[] = result.data
    return data
  }

  @Action({ commit: 'SET_ALL_RESOURCES' })
  async loadResourcesForMarket (marketKey: string) {
    const result = await axios.get(`/resources/market${marketKey ? ('/' + marketKey) : ''}`, { withCredentials: true })
    const data: Resource[] = result.data
    return data
  }

  @Action({ commit: 'SET_ALL_RESOURCES' })
  async loadResourcesKeysForMarket (marketKey: string) {
    const result = await axios.get(`/resources/market/keys${marketKey ? ('/' + marketKey) : ''}`, { withCredentials: true })
    const data: Resource[] = result.data
    return data
  }

  @Action({ commit: 'SET_ALL_PRODUCT_RESOURCES' })
  async loadProductResources (productKey: string) {
    const result = await axios.get(`/resources/product/${productKey}`, { withCredentials: true })

    const data: Resource[] = result.data
    return data
  }

  @Action({ commit: 'SET_ALL_PRODUCT_RESOURCES' })
  async loadProductResourcesSearch (productKey: string) {
    const result = await axios.get(`/resources/product/search/${productKey}`, { withCredentials: true })

    const data: Resource[] = result.data
    return data
  }

  @Action({ commit: 'SET_ALL_DRAFT_PRODUCT_RESOURCES' })
  async loadProductDraftResources (productKey: string) {
    const result = await axios.get(`/resources/drafts/product/${productKey}`, { withCredentials: true })
    const data: Resource[] = result.data
    return data
  }

  @Action({ commit: 'SET_ALL_PRODUCT_RESOURCES' })
  async loadProductResourcesForMarket (productKey: string) {
    const result = await axios.get(`/resources/product/${productKey}/market/${store.getters.getSelectedMarket}`, { withCredentials: true })
    const data: Resource[] = result.data
    return data
  }

  // drafts
  @Action({ commit: 'SET_ALL_DRAFT_RESOURCES' })
  async loadAllDraftResources () {
    const result = await axios.get('/resources/drafts', { withCredentials: true })
    const data: Resource[] = result.data
    return data
  }

  @Action({ commit: 'SET_ALL_DRAFT_RESOURCES' })
  async loadDraftResourcesForMarket (marketKey: string) {
    const result = await axios.get(`/resources/drafts/market${marketKey ? ('/' + marketKey) : ''}`, { withCredentials: true })
    const data: Resource[] = result.data
    return data
  }

  get getAllResources () {
    return this.allResources
  }

  get getAllDraftResources () {
    return this.allDraftResources
  }

  get getAllProductResources () {
    return this.allProductResources
  }

  get getAllDraftProductResources () {
    return this.allDraftProductResources
  }

  get getResourcesForProductKey () {
    return (productKey: string) => {
      if (!this.allResources || !this.allResources.length) {
        return []
      }
      return this.allResources.filter((r: Resource) => {
        return r.products.some((p: Product) => p.key === productKey)
      })
    }
  }

  get getResourceByKey () {
    return (key: string) => {
      return this.allResources.find(resource => resource.key === key) || {
        name: '',
        resourceType: {
          name: '',
          key: ''
        },
        filename: '',
        externalLink: '',
        gvdPage: null,
        slideDeckPage: null,
        markets: [],
        products: []
      }
    }
  }

  get getDraftResourceByKey () {
    return (key: string) => {
      return this.allDraftResources.find(resource => resource.key === key) || {
        name: '',
        resourceType: {
          name: '',
          key: ''
        },
        filename: '',
        externalLink: '',
        gvdPage: null,
        slideDeckPage: null,
        markets: [],
        products: []
      }
    }
  }

  get getResourceTypes () {
    return this.resourceTypes
  }

  get getResourceTags () {
    return this.resourceTags
  }

  get getResourceTagCategories () {
    return this.resourceTagCategories
  }

  // reused for both slide decks and gvds
  @Mutation
  SET_ACTIVE_RESOURCE_TYPES (resourceTypes: ResourceType[]) {
    this.resourceTypes = resourceTypes
  }

  @Action({ commit: 'SET_ACTIVE_RESOURCE_TYPES' })
  async loadResourceTypes () {
    const result = await axios.get('/resource-types', { withCredentials: true })

    const data: ResourceType[] = result.data
    return data
  }

  @Mutation
  SET_ACTIVE_RESOURCE_TAGS (resourceTags: ResourceTag[]) {
    this.resourceTags = resourceTags
  }

  @Action({ commit: 'SET_ACTIVE_RESOURCE_TAGS' })
  async loadResourceTags () {
    const result = await axios.get('/resource-tags', { withCredentials: true })

    const data: ResourceTag[] = result.data
    return data
  }

  @Mutation
  SET_ACTIVE_RESOURCE_TAG_CATEGORIES (resourceTagCategories: ResourceTagCategory[]) {
    this.resourceTagCategories = resourceTagCategories
  }

  @Action({ commit: 'SET_ACTIVE_RESOURCE_TAG_CATEGORIES' })
  async loadResourceTagCategories () {
    const result = await axios.get('/resource-tag-categories', { withCredentials: true })

    const data: ResourceTagCategory[] = result.data
    return data
  }

  // admin methods:
  @Action
  async addResourceToGvdPage (data: any) {
    const result = await axios.post('/resources/add-to-gvd-page', data, { withCredentials: true })
    return result
  }

  @Action
  async removeResourceFromGvdPage (data: any) {
    const result = await axios.post('/resources/remove-from-gvd-page', data, { withCredentials: true })
    return result
  }

  @Action
  async addResourceToSlideDeckPage (data: any) {
    const result = await axios.post('/resources/add-to-slide-deck-page', data, { withCredentials: true })
    return result
  }

  @Action
  async removeResourceFromSlideDeckPage (data: any) {
    const result = await axios.post('/resources/remove-from-slide-deck-page', data, { withCredentials: true })
    return result
  }

  @Action async addResource (newResource: Resource) {
    const result = await axios.post(`${this.apiUrlRoot}`, newResource, { withCredentials: true })
    const data: Resource = result.data
    return data
  }

  @Action async addResourceTagCategory (newResourceTagCategory: ResourceTagCategory) {
    const result = await axios.post('resource-tag-categories', newResourceTagCategory, { withCredentials: true })
    const data: ResourceTagCategory = result.data
    return data
  }

  @Action async addResourceCategory (newResourceCategory: ResourceType) {
    const result = await axios.post('admin/resource-types', newResourceCategory, { withCredentials: true })
    const data: ResourceType = result.data
    return data
  }

  @Action async addResourceTag (newResourceTag: ResourceTag) {
    const result = await axios.post('resource-tags', newResourceTag, { withCredentials: true })
    const data: ResourceTag = result.data
    return data
  }

  @Action async saveResource (resourceToSave: Resource) {
    const result = await axios.post(`${this.apiUrlRoot}`, resourceToSave, { withCredentials: true })
    return result
  }

  @Action async saveResources (resourcesToSave: Resource[]) {
    const result = await axios.post(`${this.apiUrlRoot}/bulk-save`, resourcesToSave, { withCredentials: true })
    return result
  }

  @Action async saveResourceFile ({ key, formData } : { key: string, formData: FormData}) {
    const result = await axios.post(`${this.apiUrlRoot}/file-upload/${key}`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true })
    return result
  }

  @Action async bulkAddResources (newResourcesConfig: any) {
    const resourcesFormData = new FormData()
    for (let i = 0; i < newResourcesConfig.files.length; i++) {
      const file = newResourcesConfig.files[i]
      resourcesFormData.append(`file_${i}`, file)
    }
    if (newResourcesConfig.resourceType) {
      resourcesFormData.append('resourceType', newResourcesConfig.resourceType)
    }
    resourcesFormData.append('resourceTags', newResourcesConfig.resourceTags)
    resourcesFormData.append('products', newResourcesConfig.products)
    resourcesFormData.append('markets', newResourcesConfig.markets)
    const result = await axios.post(`${this.apiUrlRoot}/bulk-add/`, resourcesFormData, { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true })
    const data: string[] = result.data
    return data
  }

  @Action async publishResource (resourceToPublish: Resource) {
    const result = await axios.post(`${this.apiUrlRoot}/publish`, resourceToPublish, { withCredentials: true })
    return result
  }

  @Action async publishResources (resourcesToPublish: Resource[]) {
    const result = await axios.post(`${this.apiUrlRoot}/bulk-publish`, resourcesToPublish, { withCredentials: true })
    return result
  }

  @Action async deleteResource (resourceToDelete: Resource) {
    const result = await axios.delete(`${this.apiUrlRoot}/${resourceToDelete.key}`, { withCredentials: true })
    return result
  }

  @Action async deleteResourceTagCategory (resourceTagCategoryToDelete: ResourceTagCategory) {
    const result = await axios.delete(`resource-tag-categories/${resourceTagCategoryToDelete.key}`, { withCredentials: true })
    return result
  }

  @Action async deleteResourceCategory (resourceCategoryToDelete: ResourceType) {
    const result = await axios.delete(`resource-types/${resourceCategoryToDelete.key}`, { withCredentials: true })
    return result
  }

  @Action async deleteResourceTag (resourceTagToDelete: ResourceTag) {
    const result = await axios.delete(`resource-tags/${resourceTagToDelete.key}`, { withCredentials: true })
    return result
  }
}
