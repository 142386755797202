











import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Footer extends Vue {
  get isAuthenticated () {
    return this.$store.getters.isAuthenticated
  }
}
